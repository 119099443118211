html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Optional: Remove scroll bars on browser window */
}

.pdf-page {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

iframe {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  box-sizing: border-box;
}
